*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  font-size: 14px;
}

body.sticky-footer {
  margin-bottom: 56px;
}

.sticky-footer small{
  color: #fff;
}

.bg-custom{
  background-color: #C13331!important;
}

body.sticky-footer .content-wrapper {
  min-height: calc(100vh - 56px - 56px);
}

body.fixed-nav {
  padding-top: 56px;
}

.content-wrapper {
  min-height: calc(100vh - 56px);
  padding-top: 5rem;
}

.smaller {
  font-size: 0.7rem;
}

.o-hidden {
  overflow: hidden !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.flex{
  display: flex;
  flex-wrap: wrap;
}
.align-center{
  align-items: center;
}
.space-between{
  justify-content: space-between;
}

input, select {
  padding: 6px 12px!important;
}
#mainNav .navbar-collapse {
  overflow: auto;
  max-height: 75vh;
}
nav#mainNav {
  justify-content: flex-start;
}

#mainNav .navbar-collapse .navbar-nav .nav-item .nav-link {
  cursor: pointer;
  font-size: 18px;
}

#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
  float: right;
  content: '\f107';
  font-family: 'FontAwesome';
}

#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse.collapsed:after {
  content: '\f105';
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level {
  padding-left: 0;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a {
  display: block;
  padding: 0.5em 0;
  text-decoration: none;
}

.navbar.bg-dark, .bg-dark{
  background-color: #000!important;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a:focus, #mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a:hover,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a:focus,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a:hover {
  text-decoration: none;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a {
  padding-left: 1em;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a {
  padding-left: 2em;
}

#mainNav .navbar-collapse .sidenav-toggler {
  display: none;
}

#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link {
  position: relative;
  min-width: 45px;
}

#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
  float: right;
  width: auto;
  content: '\f105';
  border: none;
  font-family: 'FontAwesome';
}

#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link .indicator {
  position: absolute;
  top: 5px;
  left: 21px;
  font-size: 10px;
}

#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown.show > .nav-link:after {
  content: '\f107';
}

#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown-item > .dropdown-message {
  overflow: hidden;
  max-width: none;
  text-overflow: ellipsis;
}

.left-nav {
  overflow: scroll;
}

@media (min-width: 992px) {
  #mainNav .navbar-brand {
    width: 250px;
  }
  #mainNav .navbar-collapse {
    overflow: visible;
    max-height: none;
  }
  #mainNav .navbar-collapse .navbar-sidenav {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 65px;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item {
    width: 250px;
    padding: 0;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
    padding: 0.6em;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level,
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level {
    padding-left: 0;
    list-style: none;
  }

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link i {
  margin-right: 10px;
}
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li,
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li {
    width: 250px;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a,
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
    padding: 0.6em 1em;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a {
    padding-left: 2.75em;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
    padding-left: 3.75em;
  }
  #mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link {
    min-width: 0;
  }
  #mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
    width: 24px;
    text-align: center;
  }
  #mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown-item > .dropdown-message {
    max-width: 300px;
  }
}

#mainNav.fixed-top .sidenav-toggler {
  display: none;
}
.dropdown-menu a{
  cursor: pointer;
}
@media (min-width: 992px) {
  .dropdown-menu{
    right: 0;
    left: unset!important;
  }
  #mainNav.fixed-top .navbar-sidenav {
    height: 100vh;
  }
  #mainNav.fixed-top .sidenav-toggler {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: calc(100vh - 56px);
  }
  #mainNav.fixed-top .sidenav-toggler > .nav-item {
    width: 250px;
    padding: 0;
  }
  #mainNav.fixed-top .sidenav-toggler > .nav-item > .nav-link {
    padding: 1em;
  }
}

#mainNav.fixed-top.navbar-dark .sidenav-toggler {
  background-color: #212529;
}

#mainNav.fixed-top.navbar-dark .sidenav-toggler a i {
  color: #adb5bd;
}

#mainNav.fixed-top.navbar-light .sidenav-toggler {
  background-color: #dee2e6;
}

#mainNav.fixed-top.navbar-light .sidenav-toggler a i {
  color: rgba(0, 0, 0, 0.5);
}

body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler {
  overflow-x: hidden;
  width: 55px;
}
.navbar-dark .navbar-nav .nav-link{
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-item,
body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-link {
  width: 55px !important;
}

body.sidenav-toggled #mainNav.fixed-top #sidenavToggler i {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

#mainNav.static-top .sidenav-toggler {
  display: none;
}

@media (min-width: 992px) {
  #mainNav.static-top .sidenav-toggler {
    display: flex;
  }
}

body.sidenav-toggled #mainNav.static-top #sidenavToggler i {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

.content-wrapper {
  overflow-x: hidden;
  background: white;
}

@media (min-width: 992px) {
  .content-wrapper {
    margin-left: 250px;
  }
}

#sidenavToggler i {
  font-weight: 800;
}

.navbar-sidenav-tooltip.show {
  display: none;
}

@media (min-width: 992px) {
  body.sidenav-toggled .content-wrapper {
    margin-left: 55px;
  }
}

body.sidenav-toggled .navbar-sidenav {
  width: 55px;
}

body.sidenav-toggled .navbar-sidenav .nav-link-text {
  display: none;
}


body.sidenav-toggled .navbar-sidenav .nav-item,
body.sidenav-toggled .navbar-sidenav .nav-link {
  width: 55px !important;
}

body.sidenav-toggled .navbar-sidenav .nav-item:after,
body.sidenav-toggled .navbar-sidenav .nav-link:after {
  display: none;
}

body.sidenav-toggled .navbar-sidenav .nav-item {
  white-space: nowrap;
}

body.sidenav-toggled .navbar-sidenav-tooltip.show {
  display: flex;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
  color: #fff;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
  color: #fff;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link:hover {
  color: #C13331;
  cursor: pointer;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
  color: #fff;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:focus, #mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:hover,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:focus,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:hover {
  color: #C13331;
}

#mainNav.navbar-dark .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
  color: #fff;
}

@media (min-width: 992px) {
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav {
    background: #000;
  }
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a {
    color: white !important;
    background-color: #495057;
  }
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:focus, #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:hover {
    color: white;
  }
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level,
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level {
    background: #000;
    border-right: 1px solid #000;
  }
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
  color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item > .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
  color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:focus, #mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:hover,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:focus,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:hover {
  color: rgba(0, 0, 0, 0.7);
}

#mainNav.navbar-light .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 992px) {
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav {
    background: #f8f9fa;
  }
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a {
    color: #000 !important;
    background-color: #e9ecef;
  }
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:focus, #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:hover {
    color: #000;
  }
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level,
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level {
    background: #f8f9fa;
  }
}

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -25px;
  right: -10px;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.card-body-icon-small{
  position: absolute;
  z-index: 0;
  top: 0px;
  right: 5px;
  font-size: 1rem;
}

.col-md-20 {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 1;
  }
}

@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
  .col-md-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    column-count: 2;
  }
}

.card-login {
  max-width: 25rem;
}

.card-register {
  max-width: 40rem;
}

footer.sticky-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: #000;
  line-height: 55px;
}

@media (min-width: 992px) {
  footer.sticky-footer {
    width: calc(100% - 250px);
  }
}

@media (min-width: 992px) {
  body.sidenav-toggled footer.sticky-footer {
    width: calc(100% - 55px);
  }
}

.card-height .card-img-top {
  height: 185px;
  object-fit: cover;
}

.card select{
  width: 100%;
  padding: 10px;
}
.card-body.card-body-p {
  padding: 10px 10px 2px;
}
.btnspan{
  font-size: 10px;
  padding: 2px 12px;
  border-radius: 12px;
  font-weight: 700;
  cursor: pointer;

}
.success{
  color:#34c38f;
  background-color: rgba(52,195,143,.18);
}
.danger{
  color: #f46a6a;
  background-color: rgba(244,106,106,.18);
}
.pending{
  color: blue;
  background-color: rgb(204, 201, 44);
}
.login-box {
  margin-top: 75px;
  height: auto;
  background: #1A2226;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login .login-key {
  height: 100px;
  font-size: 80px;
  line-height: 100px;
  background: -webkit-linear-gradient(#27EF9F, #0DB8DE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login .login-title {
  margin-top: 15px;
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  margin-top: 15px;
  font-weight: bold;
  color: #ECF0F5;
}

.login .login-form {
  margin-top: 25px;
  text-align: left;
}

.login input[type=text] {
  background-color: #1A2226;
  border: none;
  border-bottom: 2px solid #0DB8DE;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  margin-bottom: 20px;
  padding-left: 0px;
  color: #ECF0F5;
}

.login input[type=password] {
  background-color: #1A2226;
  border: none;
  border-bottom: 2px solid #0DB8DE;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  padding-left: 0px;
  margin-bottom: 20px;
  color: #ECF0F5;
}

.login .form-group {
  margin-bottom: 40px;
  outline: 0px;
}

.login .form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #0DB8DE;
  outline: 0;
  background-color: #1A2226;
  color: #ECF0F5;
}

.login input:focus {
  outline: none;
  box-shadow: 0 0 0;
}

.login label {
  margin-bottom: 0px;
}

.login .form-control-label {
  font-size: 10px;
  color: #6C6C6C;
  font-weight: bold;
  letter-spacing: 1px;
}

.login .btn-outline-primary {
  border-color: #0DB8DE;
  color: #0DB8DE;
  border-radius: 0px;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.login .btn-outline-primary:hover {
  background-color: #0DB8DE;
  right: 0px;
}

.login .login-btm {
  float: left;
}

.login .login-button {
  padding-right: 0px;
  text-align: right;
  margin-bottom: 25px;
}

.login .login-text {
  text-align: left;
  padding-left: 0px;
  color: #A2A4A4;
}

.login .loginbttm {
  padding: 0px;
}
.primary{
  color: #556ee5;
  cursor: pointer;
}
.digit_num{
  border: 1px solid #C13331;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    /* background-color: #E6E6FA; */
    background-color: rgb(193 51 49 / 50%);
  width: 40px;
  height: 40px;
  margin: 10px 20px;
  font-weight: 600;
}
.add-name{
  display: flex;
  justify-content: right;
}

.red_bg{
  border: 1px solid #f46a6a;
  border-radius: 4px;
  background-color: rgba(244,106,106,.18);
  width: 40px;
  height: 40px;
  margin: 10px 20px;
  font-weight: 600;
}
.link{
  color: #000;
  cursor: pointer;
}
.custom-control-input{
  position: absolute;
    z-index: 1 !important;
    opacity: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
table th{
  color: #c13331;
}

table .btn {
  padding: 2px 5px;
  margin: 0px 5px;
  font-size: 12px;
}

@media (max-width:768px){
  .navbar-nav .nav-link {
    padding-right: 20px!important;
    padding-left: 20px!important;
  }
  span.nav-link-text {
    margin-left: 20px;
  }
  .card-body-icon {
    top: -8px;
    right: 0px;
    font-size: 1.5rem;
  }
}

@media (max-width:500px){
  .dashbaord-users .card-body-icon{
    display: none;
  }
  .dashbaord-users .mr-5{
    margin-right: unset!important;
  }
  .dashbaord-users .mr-5 h5{
    font-size: 13px;
    margin-bottom: 0;
  }
  .navbar-dark .navbar-brand {
    font-size: 14px;
  }
  .card-height .card-img-top {
    height: 100px;
    object-fit: contain;
  }
}

.custom-control-input:checked ~ .custom-control-label::before{
  background-color: #C13331!important;
  border-radius: 4px;
}

/* #navbarCollapse {
  position: absolute;
  top: 65px;
  left: 0;
  z-index: 1;
  max-width: 300px;
  height: 50vh;
  background: #000;
  width: 100%;
  transition: all 2s ease-in-out;
  padding: 0px 10px;
} */

.chart-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.chart-table {
  max-width: 14.28%;
  width: 100%;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.chart-table .date{
  padding: 3px;
  border: 1px solid #000;
}
.chart-table .result{
  padding: 3px;
  border: 1px solid #000;
  border-top: none;
}
.user-list .jNvkxB {
  overflow: unset;
}
.user-list .link{
  color: #0d6efd;
  text-transform: capitalize;
  text-decoration: underline;
}
.user-list .allowRowEvents{white-space: unset;}
.user-list .bElfLb, .user-list .hQlpoS{
  padding: 0px 10px!important;
}